export interface ContextType {
  isMobile: boolean;
}

export enum RouteType {
  HOMEPAGE = '',
  ABOUT_US = 'about-us',
  PRODUCT_SCAN_VIEW_MAIN = 'product-scan-main/:productId',
  PRODUCT_SCAN_VIEW_INFO = 'product-scan-info/:productId',
  PRODUCT_SCAN_VIEW_MAP = 'product-scan-map/:productId',
  PRIVACY_POLICY = 'privacy-policy'
}
