import React, { useContext, useState } from 'react';
import { StyledNavLink, StyledWrapper } from './MainNavigation-styling';
import AxeloteLogo from '../../atoms/AxeloteLogo';
import { PageContext } from '../../MainTemplate';
import { RouteType } from '../../../model/Model';
import i18next from "i18next";

const MainNavigation: React.FC = () => {
  const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(false);
  const { isMobile } = useContext(PageContext);

  const toggleMenu = () => (!isMobile ? setMobileMenuActive(false) : setMobileMenuActive(!mobileMenuActive));

  const lngs = {
    en: {nativeName: 'EN'},
    pl: {nativeName: 'PL'},
  }

  return (
      <StyledWrapper id="main-navigation">
        <div className="mobile-nav-group">
          <div className={`hamburger-menu ${mobileMenuActive && isMobile ? 'active' : ''}`} onClick={toggleMenu}>
            <div className="ham-bar bar-top"></div>
            <div className="ham-bar bar-mid"></div>
            <div className="ham-bar bar-bottom"></div>
          </div>

          {isMobile && <AxeloteLogo />}

          <div />
        </div>

        {!isMobile && <AxeloteLogo />}

        <nav className={`${mobileMenuActive && isMobile ? 'active' : ''}`}>
          <StyledNavLink to={`/${RouteType.HOMEPAGE}`} onClick={toggleMenu}>
            <span>Digital Product Passport</span>
          </StyledNavLink>
          <StyledNavLink to={`/${RouteType.ABOUT_US}`} onClick={toggleMenu}>
            <span>About us</span>
          </StyledNavLink>
          <div
              className="language-switcher"
              style={{
                marginLeft: `${isMobile ? '0' : '30px'}`,
                marginTop: `${isMobile ? '10px' : '0'}`,
                paddingTop: '20px',
                paddingBottom: '6px',
                height: '54px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
              }}
          >
            {Object.keys(lngs).map((lng, index) => (
                <React.Fragment key={lng}>
                  <button
                      style={{
                        backgroundColor: 'white',
                        color: 'black',
                        border: '1px solid black',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        padding: '5px 10px',
                      }}
                      onClick={() => {
                        i18next.changeLanguage(lng);
                        localStorage.setItem('lang', lng);
                        window.location.reload();
                      }}
                  >
                    {lngs[lng].nativeName}
                  </button>
                  {index < Object.keys(lngs).length - 1 && <span style={{ margin: '0 5px' }}>|</span>}
                </React.Fragment>
            ))}
          </div>
        </nav>
      </StyledWrapper>
  );
};

export default MainNavigation;