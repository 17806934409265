import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledContainer, StyledTitle, StyledMapPlaceholder, StyledLabel, StyledBackButton } from './ProductMapView-styling';
import {MapContainer, Marker, Popup, TileLayer, useMap} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

export const ProductMapView: React.FC<{ productId: string }> = ({ productId }) => {
  const navigate = useNavigate();
  const BASE_URL = 'https://cr-api-dev.axelote.com';
  const [productData, setProductData] = useState<any>(null);

    const customMarker = new L.Icon({
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });


    useEffect(() => {
    const fetchProductData = async () => {
      try {
        const res = await fetch(`${BASE_URL}/dpp-demo-get-product-data/${productId}`);
        if (!res.ok) throw new Error('Błąd podczas pobierania danych');

        const data = await res.json();
        setProductData(data);
      } catch (error) {
        console.error('Błąd podczas pobierania danych:', error);
      }
    };

    fetchProductData();
  }, [productId]);
  console.log(productData);

  const handleBackClick = () => {
    navigate('/product-scan-main/'+productId);
  };

  if (!productData) {
    return <div>Ładowanie danych...</div>;
  }

  return (
      <StyledContainer>
          <StyledBackButton onClick={handleBackClick}>Wróć</StyledBackButton>
          <StyledTitle>{productData.productName}</StyledTitle>
          <div style={{width: '90%', margin: '0 auto'}}>
              <MapContainer
                  style={{height: '200px', width: '100%'}}
                  center={[50.049755145422964, 18.43210748280174]} // Latitude and longitude of the map center
                  zoom={13} // Initial zoom level
                  scrollWheelZoom={false} // Disable zooming with scroll
              >
                  <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={[50.049755145422964, 18.43210748280174]} icon={customMarker}>
                      <Popup>
                          A pretty CSS3 popup. <br /> Easily customizable.
                      </Popup>
                  </Marker>
              </MapContainer>
          </div>
          <StyledLabel>
              <strong>Producent:</strong> {productData.producerData.companyName}
          </StyledLabel>
          <StyledLabel>
              <strong>Dostawca:</strong> {productData.distributor}
          </StyledLabel>
          <StyledLabel>
              <strong>Km przebyte:</strong>{' '}
          </StyledLabel>
          <StyledLabel>
              <strong>Ślad węglowy:</strong> {productData.productLifeCycle.carbonFootprint}
          </StyledLabel>
      </StyledContainer>
  );
};
