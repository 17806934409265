import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import qrcode from '../../public/assets/new/dppappweb1test2.jpg';
import image1 from '../../public/assets/new/banemain1.jpg';
import image2 from '../../public/assets/new/banermain2.jpg';
import image3 from '../../public/assets/new/banermain3.jpg';
import image4 from '../../public/assets/new/image4.webp';
import image5 from '../../public/assets/new/image5.webp';
import image6 from '../../public/assets/new/odkryj_nowe_mozliwosci.jpg';
import imageAxelote from '../../public/assets/new/jak_dziala_axelote.jpg';
import imageBenefits from '../../public/assets/new/korzysci_dla_twojej_firmy_main.jpg';
import { trans } from "../service/TranslationService";

interface StyledHomepageProps {
  image1: string;
  image2: string;
  image3: string;
  image4: string;
  image5: string;
  image6: string;
  qrcode: string;
  imageAxelote: string;
  imageBenefits: string;
}

export const StyledHomepage = styled.div<StyledHomepageProps>`
  --font-size-h1: 2em;
  --font-size-h2: 1.6em;
  --font-size-p: 1.2em;
  --font-size-li: 1.1em;

  .image-1,
  .image-2,
  .image-3,
  .image-4,
  .image-5,
  .image-6,
  .image-axelote,
  .image-benefits {
    background-repeat: no-repeat;
    background-size: cover;
  }

  .image-axelote {
    background-image: ${({ imageAxelote }) => `url(${imageAxelote})`};
    background-position: center;
    border-radius: 10px;
    min-height: 370px;

    @media (max-width: 768px) {
      min-width: 100%;
    }
  }

  .image-benefits {
    background-image: ${({ imageBenefits }) => `url(${imageBenefits})`};
    width: 30vw;
    height: 50vh;
    position: relative;
    background-position: center;
    border-radius: 10px;
    /* top: 40px; */
    /* right: 20px; */

    @media (max-width: 768px) {
      /* position: relative; */
      width: 100%;
      height: 40vh;
    }
  }

  .image-1 {
    background-image: ${({ image1 }) => `url(${image1})`};
  }

  .image-2 {
    background-image: ${({ image2 }) => `url(${image2})`};
  }

  .image-3 {
    background-image: ${({ image3 }) => `url(${image3})`};
  }

  .image-4 {
    background-image: ${({ image4 }) => `url(${image4})`};
  }

  .image-5 {
    background-image: ${({ image5 }) => `url(${image5})`};
  }

  .image-6 {
    background-image: ${({ image6 }) => `url(${image6})`};
  }

  #dpp-info1 {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 40px;
    background-color: #f9f9f9; /* Soft background color */
    color: #333;
    box-sizing: border-box;
    flex-wrap: wrap; /* Makes it responsive */
    margin: 40px 90px;
    border-radius: 10px;
    font-size: 1.2rem;
  }

  #dpp-info1 .content {
    flex: 1;
    max-width: 600px;
  }

  #dpp-info1 h1 {
    font-size: var(--font-size-h1);
    color: #0073e6; /* Accent color */
    margin-bottom: 20px;
  }

  #dpp-info1 h2 {
    font-size: var(--font-size-h2);
    margin-top: 30px;
    color: #444;
  }

  #dpp-info1 p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 15px;
  }

  #dpp-info1 ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 15px;
  }

  #dpp-info1 ul li {
    font-size: var(--font-size-li);
    margin-bottom: 10px;
    line-height: 1.5;
  }

  #dpp-info1 ul li strong {
    /* color: #0073e6; Highlighted color */
  }

  #dpp-info1 .image {
    flex: 1;
    height: 274px;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    #dpp-info1 {
      flex-direction: column;
      align-items: center;
      margin: 40px 0;
    }

    #dpp-info1 .content {
      max-width: 100%;
      text-align: center;
      font-size: 1.1rem;
    }

    #dpp-info1 ul {
      list-style-type: none; /* Remove bullets on mobile */
      padding-left: 0;
    }
  }

  #dpp-info2 {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 40px;
    background-color: #f9f9f9; /* Soft background color */
    color: #333;
    box-sizing: border-box;
    flex-wrap: wrap; /* Makes it responsive */
    margin: 40px 90px;
    border-radius: 10px;
  }

  #dpp-info2 .content {
    flex: 1;
    font-size: 1.2rem;
  }

  #dpp-info2 h1 {
    font-size: var(--font-size-h1);
    color: #0073e6; /* Accent color */
    margin-bottom: 20px;
  }

  #dpp-info2 h2 {
    font-size: var(--font-size-h2);
    margin-top: 30px;
    color: #444;
  }

  #dpp-info2 p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 15px;
  }

  #dpp-info2 ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 15px;
  }

  #dpp-info2 ul li {
    font-size: var(--font-size-li);
    margin-bottom: 20px;
    line-height: 1.5;
  }

  #dpp-info2 ul li:first-of-type {
    margin-top: 30px;
  }

  #dpp-info2 ul li:last-of-type {
    margin-bottom: 40px;
  }

  #dpp-info2 ul li strong {
    /* color: #0073e6; Highlighted color */
  }

  #dpp-info2 .image {
    flex: 1;
    height: 486px;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    #dpp-info2 {
      margin: 40px 0;
      flex-direction: column;
      align-items: center;
    }

    #dpp-info2 .content {
      max-width: 100%;
      text-align: center;
      font-size: 1.1rem;
    }

    #dpp-info2 ul {
      list-style-type: none; /* Remove bullets on mobile */
      padding-left: 0;
    }

    #dpp-info2 p:last-of-type {
      font-size: 1.1rem;
    }

    #dpp-info2 ul li {
      font-size: 1.1rem;
    }
  }

  #live-demo {
    display: flex;
    max-width: 100%;
    padding: 40px;
    background-color: #f9f9f9;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 40px 90px;
    border-radius: 10px;
    font-size: 1.2rem;

    .rec1 {
      position: absolute;
      width: 120px;
      height: 10px;
      background: #0049ef;
      top: 0;
      left: 0;
    }

    .rec2 {
      position: absolute;
      width: 10px;
      height: 80px;
      background: #ff0096;
      top: 0;
      left: 0;
      opacity: 0.8;
    }

    .rec3 {
      position: absolute;
      width: 120px;
      height: 10px;
      background: #0049ef;
      bottom: 0;
      right: 0;
    }

    .rec4 {
      position: absolute;
      width: 10px;
      height: 80px;
      background: #ff0096;
      bottom: 0;
      right: 0;
      opacity: 0.8;
    }

    > div:first-child {
      flex: 1;
    }

    > div:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      /* padding: 0 60px; */
      padding: 0;
      flex: 1;
    }

    img {
      width: 256px;
      height: 256px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      text-align: center;
      padding: 20px;
      margin: 40px 0;

      > div:last-child {
        padding: 20px 0;
      }

      img {
        width: 200px;
        height: 200px;
      }
    }
  }

  .benefits-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-bottom: 50px;
    border-bottom: 1px solid #111;
    min-width: 100%;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  .benefits-info {
    width: 60vw;
    font-size: 1.2rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  #live-demo h1 {
    font-size: var(--font-size-h1);
    color: #0073e6; /* Primary color for the title */
    margin-bottom: 20px;
  }

  #live-demo p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 20px;
    max-width: 800px;
  }

  #live-demo h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 30px 0 15px;
  }

  #live-demo ol {
    list-style-type: decimal;
    text-align: left;
    max-width: 800px;
    margin: 0;
    padding: 0 20px;
  }

  #live-demo ol li {
    font-size: var(--font-size-li);
    margin-bottom: 15px;
    line-height: 1.5;
  }

  #live-demo ol li strong {
    /* color: #0073e6; Accent color for highlights */
  }

  #live-demo p:last-of-type {
    font-size: var(--font-size-p);
    font-weight: bold;
    color: #555;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    #live-demo h1 {
      font-size: 2.2rem;
    }

    #live-demo p:last-of-type {
      font-size: 1.2rem;
    }

    #live-demo ol li {
      font-size: 1.1rem;
    }
  }

  #dpp-app {
    width: 100%;
    padding: 40px;
    background-color: #f3f4f6;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 40px 0;
  }

  #dpp-app h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 20px;
  }

  #dpp-app p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 20px;
    max-width: 800px;
  }

  #dpp-app h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 30px 0 15px;
  }

  #dpp-app ul {
    list-style-type: disc;
    padding-left: 40px; /* Indent list for better readability */
    max-width: 800px;
  }

  #dpp-app ul li {
    font-size: var(--font-size-li);
    margin-bottom: 15px;
    line-height: 1.5;
  }

  #dpp-app ul li strong {
    color: #0073e6;
  }

  #dpp-app p:last-of-type {
    font-size: 1.2em;
    font-weight: bold;
    color: #555;
    margin-top: 20px;
  }

  #benefits {
    max-width: 100%;
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 40px 90px;
  }

  #benefits h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 20px;
  }

  #benefits p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 20px;
    max-width: 800px;
  }

  #benefits h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 30px 0 15px;
  }

  #benefits ul {
    list-style-type: disc;
    padding-left: 40px; /* Indent list for better readability */
    max-width: 800px;
  }

  #benefits ul li {
    font-size: 1.4rem;
    margin-bottom: 15px;
    line-height: 1.5;
  }

  #benefits ul li strong {
    /* color: #0073e6; */
  }

  #benefits p:last-of-type {
    font-size: var(--font-size-p);
    font-weight: bold;
    color: #555;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    #benefits {
      margin: 40px 0;
    }

    #benefits h1 {
      text-align: center;
      font-size: 2.2rem;
      margin-bottom: 20px;
    }

    #benefits ul {
      padding-left: 10px;
    }

    #benefits ul li {
      font-size: 1.1rem;
    }
  }

  #producer {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin-top: 60px;
    margin-bottom: 20px;
  }

  #producer h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 10px;
  }

  #producer p {
    /* font-size: var(--font-size-p); */
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    /* font-family: 'Montserrat', sans-serif; */
    min-width: 100%;
  }

  #producer h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 50px 0 15px;
  }

  #producer ul {
    list-style-type: disc;
    padding-left: 40px;
    min-width: 100%;
  }

  #producer ul li {
    /* font-size: var(--font-size-li); */
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.5;
  }

  #producer ul li:first-of-type {
    margin-top: 30px;
  }

  #producer ul li strong {
    /* color: #0073e6; */
  }

  #producer p:last-of-type {
    font-weight: bold;
    color: #555;
    text-align: center;
    margin-top: 40px;
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    #producer {
      margin-top: 30px;
    }

    #producer h1 {
      font-size: 2rem;
    }

    #producer ul {
      padding-left: 5px;
    }
  }

  #seller {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 20px 0;
  }

  #seller h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 10px;
  }

  #seller p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 20px;
    min-width: 100%;
    font-size: 1.2rem;
  }

  #seller h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 50px 0 15px;
  }

  #seller ul {
    list-style-type: disc;
    padding-left: 40px;
    min-width: 100%;
  }

  #seller ul li {
    margin-bottom: 20px;
    line-height: 1.5;
    font-size: 1.2rem;
  }

  #seller ul li:first-of-type {
    margin-top: 30px;
  }

  #seller ul li strong {
    /* color: #0073e6; */
  }

  #seller p:last-of-type {
    font-size: var(--font-size-p);
    font-weight: bold;
    color: #555;
    margin-top: 20px;
    text-align: center;
    margin-top: 40px;
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    #seller h1 {
      font-size: 2rem;
    }

    #seller ul {
      padding-left: 5px;
    }
  }

  #everyone {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 20px 0;
  }

  #everyone h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 10px;
  }

  #everyone p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 20px;
    min-width: 100%;
    font-size: 1.2rem;
  }

  #everyone h2 {
    color: #333;
    margin: 50px 0 15px;
  }

  #everyone ul {
    list-style-type: disc;
    padding-left: 40px;
    min-width: 100%;
  }

  #everyone ul li {
    margin-bottom: 20px;
    line-height: 1.5;
    font-size: 1.2rem;
  }

  #everyone ul li:first-of-type {
    margin-top: 30px;
  }

  #everyone ul li strong {
    /* color: #0073e6; */
  }

  #everyone p:last-of-type {
    font-size: var(--font-size-p);
    font-weight: bold;
    color: #555;
    /* color: #fa2dbc; */
    margin-top: 20px;
    text-align: center;
    margin-top: 40px;
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    #everyone h1 {
      font-size: 2rem;
    }

    #everyone ul {
      padding-left: 5px;
    }
  }

  #legal {
    max-width: 100%;
    padding: 40px;
    background-color: #f3f4f6;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 40px 90px;
  }

  #legal h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 20px;
  }

  #legal h1.with-margin {
    margin-top: 20px;
  }

  #legal p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 20px;
    font-size: 1.2rem;
  }

  #legal h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 30px 0 15px;
  }

  #legal ul {
    list-style-type: disc;
    padding-left: 40px; /* Indent list for better readability */
    max-width: 800px;
  }

  #legal ul li {
    font-size: var(--font-size-li);
    margin-bottom: 15px;
    line-height: 1.5;
    font-size: 1.2rem;
  }

  #legal ul li strong {
    /* color: #0073e6; */
  }

  #legal p:last-of-type {
    font-size: var(--font-size-p);
    font-weight: bold;
    color: #555;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    #legal {
      margin: 40px 0;
    }

    #legal ul {
      padding-left: 5px;
    }
  }

  .numberList {
    list-style: none;
  }

  .centeredText {
    text-align: center;
  }

  .preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .preview-text {
    text-align: left;
    margin-top: 20px;
    font-size: 1.4rem;

    @media (max-width: 768px) {
      font-size: 1.1rem;
      line-height: 1.4;
    }
  }

  .legal-title-container {
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      margin-top: 10px;
    }
  }

  @media (max-width: 768px) {
    .legal-title-container {
      flex-direction: column;
    }
  }
`;

export const StyledBanner = styled.div`
  background: linear-gradient(135deg, #005eff, #00d4ff);
  padding: 40px;
  border-radius: 10px;
  text-align: left;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 90px;

  @media (max-width: 768px) {
    margin: 40px 0;
    padding: 50px 10px;
    flex-direction: column;
    text-align: center;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 60%;

  @media (max-width: 768px) {
    min-width: 80%;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 2.3rem;
  margin-bottom: 22px;
  color: #fff;
`;

export const StyledImage = styled.div<{ image: string }>`
  width: 50%;
  height: 400px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledToggleArrow = styled.span`
  cursor: pointer;
  font-size: 1.6rem;
  padding: 10px;
  margin-left: 10px;
  color: #333;
  &:hover {
    color: #555;
  }
`;

const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0;
  }
  10%, 90% {
    opacity: 1;
  }
`;

export const StyledParagraphContainer = styled.div`
  position: relative;
  width: 100%;
  height: 22rem;
  overflow: hidden;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    height: 26rem;
    margin-bottom: 20px;
  }
`;

export const StyledParagraph = styled.p`
  font-size: 1.4rem;
  color: #e0e0e0;
  line-height: 1.8;
  margin-top: 40px;
  position: absolute;
  width: 100%;
  animation: ${fadeInOut} 12s infinite;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

export const StyledButton = styled.button`
  padding: 15px 30px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: white;
  background-color: #0041c2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #002d8a;
  }

  @media (max-width: 768px) {
    align-self: center;
  }
`;

const slides = [
  {
    image: image1,
    text: trans('about_us.text_1'),
  },
  {
    image: image2,
    text: trans('about_us.text_2'),
  },
  {
    image: image3,
    text: trans('about_us.text_3'),
  },
];


export const Homepage: React.FC<{ footerRef: any }> = ({ footerRef }) => {
  const [currentParagraphIndex, setCurrentParagraphIndex] = useState(0);

  const [isLegalSectionVisible, setIsLegalSectionVisible] = useState(false);

  const [isProducerVisible, setIsProducerVisible] = useState(false);
  const [isSellerVisible, setIsSellerVisible] = useState(false);
  const [isEveryoneVisible, setIsEveryoneVisible] = useState(false);

  const scrollToFooter = () => {
    footerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const toggleLegalSectionVisibility = () => {
    setIsLegalSectionVisible((prev) => !prev);
  };

  const toggleProducerVisibility = () => {
    setIsProducerVisible((prev) => !prev);
  };

  const toggleSellerVisibility = () => {
    setIsSellerVisible((prev) => !prev);
  };

  const toggleEveryoneVisibility = () => {
    setIsEveryoneVisible((prev) => !prev);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentParagraphIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 12000);

    return () => clearInterval(interval);
  }, []);

  return (
    <StyledHomepage
      image1={image1}
      image2={image2}
      image3={image3}
      image4={image4}
      image5={image5}
      image6={image6}
      qrcode={qrcode}
      imageAxelote={imageAxelote}
      imageBenefits={imageBenefits}>
      <StyledBanner>
        <ContentContainer>
          <StyledTitle>{trans('homepage.banner.title')}</StyledTitle>
          <StyledParagraphContainer>
            <StyledParagraph key={currentParagraphIndex}>{slides[currentParagraphIndex].text}</StyledParagraph>
          </StyledParagraphContainer>
          <StyledButton onClick={scrollToFooter}>{trans('homepage.banner.contact_button')}</StyledButton>
        </ContentContainer>
        <StyledImage image={slides[currentParagraphIndex].image} />
      </StyledBanner>

      <section id="dpp-info1">
        <div className="content">
          <h1>{trans('homepage.dpp_info1.title')}</h1>
          <ul>
            <li>
              <strong>{trans('homepage.dpp_info1.point_1_bold')}</strong> {trans('homepage.dpp_info1.point_1_description')}
            </li>
            <li>
              <strong>{trans('homepage.dpp_info1.point_2_bold')}</strong> {trans('homepage.dpp_info1.point_2_description')}
            </li>
            <li>
              <strong>{trans('homepage.dpp_info1.point_3_bold')}</strong> {trans('homepage.dpp_info1.point_3_description')}
            </li>
          </ul>
        </div>
        <div className="image image-axelote"></div>
      </section>

      <section id="live-demo">
        <div className="rec1"></div>
        <div className="rec2"></div>
        <div className="rec3"></div>
        <div className="rec4"></div>
        <div>
          <h1>{trans('homepage.live_demo.title')}</h1>
          <p>{trans('homepage.live_demo.description')}</p>
          <ol>
            <li>
              <strong>{trans('homepage.live_demo.feature_1_bold')}</strong> {trans('homepage.live_demo.feature_1_description')}
            </li>
            <li>
              <strong>{trans('homepage.live_demo.feature_2_bold')}</strong> {trans('homepage.live_demo.feature_2_description')}
            </li>
            <li>
              <strong>{trans('homepage.live_demo.feature_3_bold')}</strong> {trans('homepage.live_demo.feature_3_description')}
            </li>
          </ol>
        </div>
        <div>
          <img src={qrcode} />
        </div>
      </section>

      <section id="dpp-info2">
        <div className="content">
          <div>
            <h1>{trans('homepage.dpp_info2.title')}</h1>
            <p>{trans('homepage.dpp_info2.description')}</p>
            <div className="image image-6"></div>
          </div>
          <div>
            <h2 style={{ marginBottom: '20px' }}>{trans('homepage.dpp_info2.gains_title')}</h2>
            <ul>
              <li>
                <strong>{trans('homepage.dpp_info2.gain_1_bold')}</strong> {trans('homepage.dpp_info2.gain_1_description')}
              </li>
              <li>
                <strong>{trans('homepage.dpp_info2.gain_2_bold')}</strong> {trans('homepage.dpp_info2.gain_2_description')}
              </li>
              <li>
                <strong>{trans('homepage.dpp_info2.gain_3_bold')}</strong> {trans('homepage.dpp_info2.gain_3_description')}
              </li>
              <li>
                <strong>{trans('homepage.dpp_info2.gain_4_bold')}</strong> {trans('homepage.dpp_info2.gain_4_description')}
              </li>
              <li>
                <strong>{trans('homepage.dpp_info2.gain_5_bold')}</strong> {trans('homepage.dpp_info2.gain_5_description')}
              </li>
              <li>
                <strong>{trans('homepage.dpp_info2.gain_6_bold')}</strong> {trans('homepage.dpp_info2.gain_6_description')}
              </li>
            </ul>
            <p className="centeredText">
              <strong style={{color: '#0073e6'}}>{trans('homepage.dpp_info2.footer')}</strong>
            </p>
          </div>
        </div>
      </section>

      <section id="benefits">
        <h1>{trans('homepage.benefits.title')}</h1>
        <div className="benefits-container">
          <div className="benefits-info">
            <ul>
              <li>
                <strong>{trans('homepage.benefits.benefit_1_bold')}</strong> {trans('homepage.benefits.benefit_1_description')}
              </li>
              <li>
                <strong>{trans('homepage.benefits.benefit_2_bold')}</strong> {trans('homepage.benefits.benefit_2_description')}
              </li>
              <li>
                <strong>{trans('homepage.benefits.benefit_3_bold')}</strong> {trans('homepage.benefits.benefit_3_description')}
              </li>
              <li>
                <strong>{trans('homepage.benefits.benefit_4_bold')}</strong> {trans('homepage.benefits.benefit_4_description')}
              </li>
              <li>
                <strong>{trans('homepage.benefits.benefit_5_bold')}</strong> {trans('homepage.benefits.benefit_5_description')}
              </li>
              <li>
                <strong>{trans('homepage.benefits.benefit_6_bold')}</strong> {trans('homepage.benefits.benefit_6_description')}
              </li>
              <li>
                <strong>{trans('homepage.benefits.benefit_7_bold')}</strong> {trans('homepage.benefits.benefit_7_description')}
              </li>
            </ul>
          </div>
          <div className="image image-benefits"></div>
        </div>

        <div id="producer">
          <h1>{trans('homepage.producer.title')}</h1>
          <div className="preview">
    <span className="preview-text">
      <strong>{trans('homepage.producer.preview_bold')}</strong> {trans('homepage.producer.preview_description')}
    </span>
            <StyledToggleArrow onClick={toggleProducerVisibility}>{isProducerVisible ? '↑' : '↓'}</StyledToggleArrow>
          </div>
          {isProducerVisible && (
              <>
                <h2>{trans('homepage.producer.benefits_title')}</h2>
                <ul>
                  <li>
                    <strong>{trans('homepage.producer.benefit_1_bold')}</strong> {trans('homepage.producer.benefit_1_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.producer.benefit_2_bold')}</strong> {trans('homepage.producer.benefit_2_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.producer.benefit_3_bold')}</strong> {trans('homepage.producer.benefit_3_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.producer.benefit_4_bold')}</strong> {trans('homepage.producer.benefit_4_description')}
                  </li>
                </ul>
                <h2>{trans('homepage.producer.why_title')}</h2>
                <ul>
                  <li>
                    <strong>{trans('homepage.producer.why_1_bold')}</strong> {trans('homepage.producer.why_1_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.producer.why_2_bold')}</strong> {trans('homepage.producer.why_2_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.producer.why_3_bold')}</strong> {trans('homepage.producer.why_3_description')}
                  </li>
                </ul>
                <p>
                  <strong>{trans('homepage.producer.why_footer_bold')}</strong> {trans('homepage.producer.why_footer_description')}
                </p>
              </>
          )}
        </div>

        <div id="seller">
          <h1>{trans('homepage.seller.title')}</h1>
          <div className="preview">
    <span className="preview-text">
      {trans('homepage.seller.preview_description_1')}
      <strong>{trans('homepage.seller.preview_bold')}</strong>
      {trans('homepage.seller.preview_description_2')}
    </span>
            <StyledToggleArrow onClick={toggleSellerVisibility}>{isSellerVisible ? '↑' : '↓'}</StyledToggleArrow>
          </div>
          {isSellerVisible && (
              <div>
                <h2>{trans('homepage.seller.benefits_title')}</h2>
                <ul>
                  <li>
                    <strong>{trans('homepage.seller.benefit_1_bold')}</strong> {trans('homepage.seller.benefit_1_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.seller.benefit_2_bold')}</strong> {trans('homepage.seller.benefit_2_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.seller.benefit_3_bold')}</strong> {trans('homepage.seller.benefit_3_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.seller.benefit_4_bold')}</strong> {trans('homepage.seller.benefit_4_description')}
                  </li>
                </ul>
                <h2>{trans('homepage.seller.why_title')}</h2>
                <ul>
                  <li>
                    <strong>{trans('homepage.seller.why_1_bold')}</strong> {trans('homepage.seller.why_1_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.seller.why_2_bold')}</strong> {trans('homepage.seller.why_2_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.seller.why_3_bold')}</strong> {trans('homepage.seller.why_3_description')}
                  </li>
                </ul>
                <h2>{trans('homepage.seller.how_title')}</h2>
                <ul>
                  <li className="numberList">
                    <strong>{trans('homepage.seller.how_1_bold')}</strong> {trans('homepage.seller.how_1_description')}
                  </li>
                  <li className="numberList">
                    <strong>{trans('homepage.seller.how_2_bold')}</strong> {trans('homepage.seller.how_2_description')}
                  </li>
                  <li className="numberList">
                    <strong>{trans('homepage.seller.how_3_bold')}</strong> {trans('homepage.seller.how_3_description')}
                  </li>
                </ul>
                <p>
                  <strong>{trans('homepage.seller.footer')}</strong>
                </p>
              </div>
          )}
        </div>
        <div id="everyone">
          <h1>{trans('homepage.everyone.title')}</h1>
          <div className="preview">
    <span className="preview-text">
      {trans('homepage.everyone.preview_description_1')}
      <strong>{trans('homepage.everyone.preview_bold')}</strong>
      {trans('homepage.everyone.preview_description_2')}
    </span>
            <StyledToggleArrow
                onClick={toggleEveryoneVisibility}>{isEveryoneVisible ? '↑' : '↓'}</StyledToggleArrow>
          </div>
          {isEveryoneVisible && (
              <div>
                <h2>{trans('homepage.everyone.benefits_title')}</h2>
                <ul>
                  <li>
                    <strong>{trans('homepage.everyone.benefit_1_bold')}</strong> {trans('homepage.everyone.benefit_1_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.everyone.benefit_2_bold')}</strong> {trans('homepage.everyone.benefit_2_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.everyone.benefit_3_bold')}</strong> {trans('homepage.everyone.benefit_3_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.everyone.benefit_4_bold')}</strong> {trans('homepage.everyone.benefit_4_description')}
                  </li>
                </ul>
                <h2>{trans('homepage.everyone.why_title')}</h2>
                <ul>
                  <li>
                    <strong>{trans('homepage.everyone.why_1_bold')}</strong> {trans('homepage.everyone.why_1_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.everyone.why_2_bold')}</strong> {trans('homepage.everyone.why_2_description')}
                  </li>
                  <li>
                    <strong>{trans('homepage.everyone.why_3_bold')}</strong> {trans('homepage.everyone.why_3_description')}
                  </li>
                </ul>
                <h2>{trans('homepage.everyone.how_title')}</h2>
                <ul>
                  <li className="numberList">
                    <strong>{trans('homepage.everyone.how_1_bold')}</strong> {trans('homepage.everyone.how_1_description')}
                  </li>
                  <li className="numberList">
                    <strong>{trans('homepage.everyone.how_2_bold')}</strong> {trans('homepage.everyone.how_2_description')}
                  </li>
                  <li className="numberList">
                    <strong>{trans('homepage.everyone.how_3_bold')}</strong> {trans('homepage.everyone.how_3_description')}
                  </li>
                  <li className="numberList">
                    <strong>{trans('homepage.everyone.how_4_bold')}</strong> {trans('homepage.everyone.how_4_description')}
                  </li>
                </ul>
                <p>
                  <strong>{trans('homepage.everyone.footer')}</strong>
                </p>
              </div>
          )}
        </div>
      </section>

      <section id="legal">
        <div className="legal-title-container">
          <h1>{trans('homepage.legal.title')}</h1>
          <StyledToggleArrow onClick={toggleLegalSectionVisibility}>
            {isLegalSectionVisible ? trans('homepage.legal.toggle_arrow_visible') : trans('homepage.legal.toggle_arrow_hidden')}
          </StyledToggleArrow>
        </div>

        {isLegalSectionVisible && (
            <>
              <h2>{trans('homepage.legal.section_1.title')}</h2>
              <ul>
                <li>
                  <strong>{trans('homepage.legal.bold_labels.date')}</strong> {trans('homepage.legal.section_1.date')}
                </li>
                <li>
                  <strong>{trans('homepage.legal.bold_labels.purpose')}</strong> {trans('homepage.legal.section_1.purpose')}
                </li>
              </ul>

              <h2>{trans('homepage.legal.section_2.title')}</h2>
              <ul>
                <li>
                  <strong>{trans('homepage.legal.bold_labels.date')}</strong> {trans('homepage.legal.section_2.date')}
                </li>
                <li>
                  <strong>{trans('homepage.legal.bold_labels.purpose')}</strong> {trans('homepage.legal.section_2.purpose')}
                </li>
              </ul>

              <h2>{trans('homepage.legal.section_3.title')}</h2>
              <ul>
                <li>
                  <strong>{trans('homepage.legal.bold_labels.date')}</strong> {trans('homepage.legal.section_3.date')}
                </li>
                <li>
                  <strong>{trans('homepage.legal.bold_labels.purpose')}</strong> {trans('homepage.legal.section_3.purpose')}
                </li>
              </ul>

              <h1 className="with-margin">{trans('homepage.legal.extra_title')}</h1>

              <h2>{trans('homepage.legal.extra_section_1.title')}</h2>
              <ul>
                <li>
                  <strong>{trans('homepage.legal.bold_labels.purpose')}</strong> {trans('homepage.legal.extra_section_1.purpose')}
                </li>
              </ul>

              <h2>{trans('homepage.legal.extra_section_2.title')}</h2>
              <ul>
                <li>
                  <strong>{trans('homepage.legal.bold_labels.purpose')}</strong> {trans('homepage.legal.extra_section_2.purpose')}
                </li>
              </ul>

              <h2>{trans('homepage.legal.extra_section_3.title')}</h2>
              <ul>
                <li>
                  <strong>{trans('homepage.legal.bold_labels.purpose')}</strong> {trans('homepage.legal.extra_section_3.purpose')}
                </li>
              </ul>
            </>
        )}
      </section>


    </StyledHomepage>
  );
};
