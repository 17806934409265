import React from 'react';
import styled, { keyframes } from 'styled-components';
import image1 from '../../public/assets/new/baner_about_us.jpg';
import image2 from '../../public/assets/new/korzysci_ze_wspolpracy.jpg';
import { trans } from "../service/TranslationService";

export const StyledAboutUs = styled.div`
  --font-size-h1: 2em;
  --font-size-h2: 1.6em;
  --font-size-p: 1.2em;
  --font-size-li: 1.1em;

  #team {
    max-width: 100%;
    padding: 40px;
    background-color: #f3f4f6;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 40px 90px;
  }

  #team h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 20px;
  }

  #team p {
    font-size: var(--font-size-p);
    line-height: 1.6;
    margin-bottom: 20px;
    max-width: 800px;
  }

  #team h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 30px 0 15px;
  }

  #team ul {
    list-style-type: disc;
    padding-left: 40px; /* Indent list for better readability */
    max-width: 800px;
  }

  #team ul li {
    /* font-size: var(--font-size-li); */
    margin-bottom: 15px;
    line-height: 1.5;
    font-size: 1.2rem;
  }

  #team ul li strong {
    color: #0073e6;
  }

  #team p:last-of-type {
    font-size: 1.2em;
    /* font-weight: bold; */
    color: #555;
    margin-top: 20px;
  }

  #team .team-card {
    background: #fff;
    padding: 25px;
    border-radius: 10px;
    margin: 20px 0;

    p {
      max-width: 100%;
      font-size: 1.4rem;
    }
  }

  @media (max-width: 768px) {
    #team {
      margin: 40px 0;
    }

    #team h1 {
      font-size: 1.8rem;
    }

    #team .team-card p {
      font-size: 1.1rem;
    }
  }

  #services {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    max-width: 100%;
    padding: 40px;
    background-color: #f3f4f6;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 40px 90px;
  }

  #services-container {
  }

  #services h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 20px;
  }

  #services p {
    line-height: 1.6;
    margin-bottom: 20px;
    max-width: 800px;
  }

  #services h2 {
    font-size: var(--font-size-h2);
    color: #333;
    margin: 30px 0 15px;
  }

  #services ul {
    list-style-type: disc;
    padding-left: 40px; /* Indent list for better readability */
    max-width: 800px;
  }

  #services ul li {
    font-size: var(--font-size-li);
    margin-bottom: 15px;
    line-height: 1.5;
    font-size: 1.4rem;
  }

  #services ul li strong {
    /* color: #0073e6; */
  }

  #services p:last-of-type {
    font-size: 1.2em;
    font-weight: bold;
    color: #555;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    #services {
      margin: 40px 0;
      flex-direction: column;
    }

    #services h1 {
      font-size: 1.8rem;
    }

    #services ul {
      padding-left: 10px;
    }

    #services ul li {
      font-size: 1.1rem;
    }
  }

  #services2 {
    max-width: 100%;
    padding: 40px;
    background-color: #f3f4f6;
    border-radius: 10px;
    color: #333;
    box-sizing: border-box;
    text-align: left;
    margin: 40px 90px;
  }

  #services2 h1 {
    font-size: var(--font-size-h1);
    color: #0073e6;
    margin-bottom: 20px;
  }

  #services2 p {
    line-height: 1.6;
    margin-bottom: 20px;
    min-width: 100%;
    font-size: 1.2rem;
  }

  #services2 h2 {
    font-size: 1.4rem;
    color: #333;
    margin: 30px 0 15px;
  }

  #services2 ul {
    list-style-type: disc;
    padding-left: 40px; /* Indent list for better readability */
    min-width: 100%;
  }

  #services2 ul li {
    font-size: var(--font-size-li);
    margin-bottom: 15px;
    line-height: 1.5;
    font-size: 1.4rem;
    min-width: 100%;
  }

  #services2 ul li strong {
    /* color: #0073e6; */
  }

  #services2 p:last-of-type {
    font-size: 1.2em;
    font-weight: bold;
    color: #555;
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    #services2 {
      margin: 40px 0;
    }

    #services2 h1 {
      font-size: 1.8rem;
    }

    #services2 ul {
      padding-left: 10px;
    }

    #services2 ul li {
      font-size: 1.1rem;
    }
  }

  .rec1 {
    position: absolute;
    width: 120px;
    height: 10px;
    background: #0049ef;
    top: 0;
    left: 0;
  }

  .rec2 {
    position: absolute;
    width: 10px;
    height: 80px;
    background: #ff0096;
    top: 0;
    left: 0;
    opacity: 0.8;
  }

  .rec3 {
    position: absolute;
    width: 120px;
    height: 10px;
    background: #0049ef;
    bottom: 0;
    right: 0;
  }

  .rec4 {
    position: absolute;
    width: 10px;
    height: 80px;
    background: #ff0096;
    bottom: 0;
    right: 0;
    opacity: 0.8;
  }

  .centeredText {
    text-align: center;
  }

  @media (max-width: 768px) {
    .centeredText {
      font-size: 1.1rem;
    }
  }
`;

export const StyledBanner = styled.div`
  background: linear-gradient(135deg, #005eff, #00d4ff);
  padding: 50px;
  border-radius: 10px;
  text-align: left;
  max-width: 100%;
  max-width: 100%;
  display: flex;
  gap: 50px;
  justify-content: space-between;
  align-items: center;
  margin: 40px 90px;

  @media (max-width: 768px) {
    margin: 40px 0;
    padding: 50px 10px;
    flex-direction: column;
    text-align: center;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 60%;

  @media (max-width: 768px) {
    min-width: 80%;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 2.3rem;
  margin-bottom: 24px;
  color: #fff;
`;

export const StyledImage = styled.div<{ image: string }>`
  width: 50%;
  height: 400px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const fadeInOut = keyframes`
    0%, 100% {
        opacity: 0;
    }
    10%, 90% {
        opacity: 1;
    }
`;

export const StyledParagraphContainer = styled.div`
  position: relative;
  width: 100%;
  height: 9.8rem;
  overflow: hidden;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    height: 16rem;
    margin-bottom: 40px;
  }
`;

export const StyledParagraph = styled.p`
  font-size: 1.4rem;
  color: #e0e0e0;
  line-height: 1.8;
  position: absolute;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const StyledButton = styled.button`
  padding: 15px 30px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: white;
  background-color: #0041c2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #002d8a;
  }

  @media (max-width: 768px) {
    align-self: center;
  }
`;

export const AboutUs: React.FC<{ footerRef: any }> = ({ footerRef }) => {
  const scrollToFooter = () => {
    footerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
      <StyledAboutUs>
        <StyledBanner>
          <ContentContainer>
            <StyledTitle>{trans('about_us.title')}</StyledTitle>
            <StyledParagraphContainer>
              <StyledParagraph>
                {trans('about_us.description.paragraph')}
              </StyledParagraph>
            </StyledParagraphContainer>
            <StyledButton onClick={scrollToFooter}>{trans('about_us.contact_button')}</StyledButton>
          </ContentContainer>
          <StyledImage image={image1} />
        </StyledBanner>

        <section id="services">
          <div className="rec1"></div>
          <div className="rec2"></div>
          <div className="rec3"></div>
          <div className="rec4"></div>
          <div id="services-container">
            <h1>{trans('about_us.services.section_title')}</h1>
            <ul>
              <li>
                <strong>{trans('about_us.services.items.scalability.title')}</strong> – {trans('about_us.services.items.scalability.description')}
              </li>
              <li>
                <strong>{trans('about_us.services.items.transparency.title')}</strong> – {trans('about_us.services.items.transparency.description')}
              </li>
              <li>
                <strong>{trans('about_us.services.items.security.title')}</strong> – {trans('about_us.services.items.security.description')}
              </li>
            </ul>
          </div>
          <StyledImage image={image2} style={{ backgroundPosition: 'center' }} />
        </section>

        <section id="services2">
          <h1>{trans('about_us.services_details.section_title')}</h1>
          <h2>{trans('about_us.services_details.audit.title')}</h2>
          <ul>
            <li>{trans('about_us.services_details.audit.items.0')}</li>
            <li>{trans('about_us.services_details.audit.items.1')}</li>
          </ul>
          <h2>{trans('about_us.services_details.custom_dpp.title')}</h2>
          <ul>
            <li>{trans('about_us.services_details.custom_dpp.items.0')}</li>
            <li>{trans('about_us.services_details.custom_dpp.items.1')}</li>
          </ul>
          <h2>{trans('about_us.services_details.integration.title')}</h2>
          <ul>
            <li>{trans('about_us.services_details.integration.items.0')}</li>
            <li>{trans('about_us.services_details.integration.items.1')}</li>
          </ul>
          <h2>{trans('about_us.services_details.training.title')}</h2>
          <ul>
            <li>{trans('about_us.services_details.training.items.0')}</li>
            <li>{trans('about_us.services_details.training.items.1')}</li>
          </ul>
          <h2>{trans('about_us.services_details.monitoring.title')}</h2>
          <ul>
            <li>{trans('about_us.services_details.monitoring.items.0')}</li>
            <li>{trans('about_us.services_details.monitoring.items.1')}</li>
          </ul>
          <p className="centeredText" style={{ color: '#0073e6', fontSize: '1.6rem', marginTop: '40px' }}>
            {trans('about_us.services_details.custom_solutions')}
          </p>
        </section>

        <section id="team">
          <h1>{trans('about_us.team.section_title')}</h1>
          <div className="team-card">
            <h2>{trans('about_us.team.members.project_manager.title')}</h2>
            <p>{trans('about_us.team.members.project_manager.description')}</p>
          </div>
          <div className="team-card">
            <h2>{trans('about_us.team.members.business_analyst.title')}</h2>
            <p>{trans('about_us.team.members.business_analyst.description')}</p>
          </div>
          <div className="team-card">
            <h2>{trans('about_us.team.members.senior_developer_1.title')}</h2>
            <p>{trans('about_us.team.members.senior_developer_1.description')}</p>
          </div>
          <div className="team-card">
            <h2>{trans('about_us.team.members.senior_developer_2.title')}</h2>
            <p>{trans('about_us.team.members.senior_developer_2.description')}</p>
          </div>
          <div className="team-card">
            <h2>{trans('about_us.team.members.regular_developer_1.title')}</h2>
            <p>{trans('about_us.team.members.regular_developer_1.description')}</p>
          </div>
          <div className="team-card">
            <h2>{trans('about_us.team.members.regular_developer_2.title')}</h2>
            <p>{trans('about_us.team.members.regular_developer_2.description')}</p>
          </div>
        </section>
      </StyledAboutUs>

  );
};
