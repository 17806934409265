import React from 'react';
import { trans } from '../service/TranslationService';

const PrivacyPolicy: React.FC = () => {
  return (
      <>
        <h1>{trans('privacy_policy.title')}</h1>

        <p>
          <strong>{trans('privacy_policy.company_name')}</strong>
          <br />
          {trans('privacy_policy.nip')}
          <br />
          {trans('privacy_policy.address')}
        </p>

        <h2>{trans('privacy_policy.introduction.title')}</h2>
        <p>{trans('privacy_policy.introduction.content')}</p>

        <h2>{trans('privacy_policy.data_controller.title')}</h2>
        <p>{trans('privacy_policy.data_controller.content')}</p>

        <h2>{trans('privacy_policy.data_scope.title')}</h2>
        <p>{trans('privacy_policy.data_scope.content')}</p>
        <ul>
          <li>{trans('privacy_policy.data_scope.items.0')}</li>
          <li>{trans('privacy_policy.data_scope.items.1')}</li>
        </ul>

        <h2>{trans('privacy_policy.cookies_usage.title')}</h2>
        <p>{trans('privacy_policy.cookies_usage.content')}</p>
        <ul>
          <li>{trans('privacy_policy.cookies_usage.items.0')}</li>
          <li>{trans('privacy_policy.cookies_usage.items.1')}</li>
          <li>{trans('privacy_policy.cookies_usage.items.2')}</li>
        </ul>
        <h3>{trans('privacy_policy.cookies_usage.cookie_types.title')}</h3>
        <ul>
          <li>{trans('privacy_policy.cookies_usage.cookie_types.items.0')}</li>
        </ul>

        <h2>{trans('privacy_policy.processing_purpose.title')}</h2>
        <p>{trans('privacy_policy.processing_purpose.content')}</p>

        <h2>{trans('privacy_policy.legal_basis.title')}</h2>
        <p>{trans('privacy_policy.legal_basis.content')}</p>
        <ul>
          <li>{trans('privacy_policy.legal_basis.items.0')}</li>
        </ul>

        <h2>{trans('privacy_policy.data_transfer.title')}</h2>
        <p>{trans('privacy_policy.data_transfer.content')}</p>

        <h2>{trans('privacy_policy.user_rights.title')}</h2>
        <p>{trans('privacy_policy.user_rights.content')}</p>
        <ul>
          <li>{trans('privacy_policy.user_rights.items.0')}</li>
          <li>{trans('privacy_policy.user_rights.items.1')}</li>
          <li>{trans('privacy_policy.user_rights.items.2')}</li>
          <li>{trans('privacy_policy.user_rights.items.3')}</li>
          <li>{trans('privacy_policy.user_rights.items.4')}</li>
          <li>{trans('privacy_policy.user_rights.items.5')}</li>
        </ul>

        <h2>{trans('privacy_policy.cookies_management.title')}</h2>
        <p>{trans('privacy_policy.cookies_management.content')}</p>

        <h2>{trans('privacy_policy.policy_changes.title')}</h2>
        <p>{trans('privacy_policy.policy_changes.content')}</p>

        <h2>{trans('privacy_policy.contact.title')}</h2>
        <p>{trans('privacy_policy.contact.content')}</p>
        <p>
          <strong>{trans('privacy_policy.company_name')}</strong>
          <br />
          {trans('privacy_policy.address')}
          <br />
          {trans('privacy_policy.contact.email_placeholder')}
        </p>

        <p>{trans('privacy_policy.effective_date')}</p>
      </>
  );
};

export default PrivacyPolicy;