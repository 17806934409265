import React from 'react';
import {StyledCompanyName, StyledFooterWrapper, StyledLink, StyledLinksWrapper, StyledText} from './Footer-styling';
import ContactUsForm from '../contactUsForm/ContactUsForm';
import { trans } from "../../../service/TranslationService";

const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear();

    return (
        <StyledFooterWrapper>
            <div>
                <StyledCompanyName>© {currentYear} axelote</StyledCompanyName>
              <StyledText>
                <b>{trans('homepage.contact.header')}</b> {trans('homepage.contact.description')}
              </StyledText>
            </div>
            <ContactUsForm/>
        </StyledFooterWrapper>
    );
};

export default Footer;
