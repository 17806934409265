import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  StyledTable,
  StyledTitle,
  StyledTableRow,
  StyledTableHeader,
  StyledTableData,
  StyledButton,
  StyledContainer,
  StyledSubRow,
  StyledBackButton,
  StyledSubTableData,
} from './ProductInfoView-styling';

export const ProductInfoView: React.FC<{ productId: string }> = ({ productId }) => {
  const BASE_URL = 'https://cr-api-dev.axelote.com';
  const navigate = useNavigate();
  const [productData, setProductData] = useState<any>(null);
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const res = await fetch(`${BASE_URL}/dpp-demo-get-product-data/${productId}`);
        if (!res.ok) throw new Error('Błąd podczas pobierania danych');

        const data = await res.json();
        setProductData(data);
      } catch (error) {
        console.error('Błąd podczas pobierania danych:', error);
      }
    };

    fetchProductData();
  }, []);
  console.log(productData);

  const toggleRow = (id: string) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev);
      if (newExpandedRows.has(id)) {
        newExpandedRows.delete(id);
      } else {
        newExpandedRows.add(id);
      }
      return newExpandedRows;
    });
  };

  const handleBackClick = () => {
    navigate('/product-scan-main/'+productId);
  };

  if (!productData) {
    return <div>Ładowanie danych...</div>;
  }

  return (
    <StyledContainer>
      <StyledBackButton onClick={handleBackClick}>Wróć</StyledBackButton>
      <StyledTitle>Informacje o produkcie</StyledTitle>
      <StyledTable>
        <thead>
          <StyledTableRow>
            <StyledTableHeader>Kategoria</StyledTableHeader>
            <StyledTableHeader>Szczegóły</StyledTableHeader>
          </StyledTableRow>
        </thead>
        <tbody>
          <React.Fragment>
            <StyledTableRow onClick={() => toggleRow('productName')}>
              <StyledTableData>
                <strong>Nazwa produktu:</strong>
              </StyledTableData>
              <StyledTableData>{productData.productName || productData.name}</StyledTableData>
            </StyledTableRow>
            <StyledTableRow onClick={() => toggleRow('manufacturer')}>
              <StyledTableData>
                <strong>Producent:</strong>
              </StyledTableData>
              <StyledTableData>{productData.producerData.companyName}</StyledTableData>
            </StyledTableRow>
            {expandedRows.has('manufacturer') && (
              <>
                <StyledSubRow>
                  <StyledSubTableData>
                    <strong>Adres siedziby:</strong>
                  </StyledSubTableData>
                  <StyledSubTableData>{productData.producerData.address}</StyledSubTableData>
                </StyledSubRow>
                <StyledSubRow>
                  <StyledSubTableData>
                    <strong>Numer telefonu:</strong>
                  </StyledSubTableData>
                  <StyledSubTableData>{productData.producerData.contactPhone}</StyledSubTableData>
                </StyledSubRow>
                <StyledSubRow>
                  <StyledSubTableData>
                    <strong>Kraj:</strong>
                  </StyledSubTableData>
                  <StyledSubTableData>{productData.producerData.country}</StyledSubTableData>
                </StyledSubRow>
              </>
            )}
            <StyledTableRow onClick={() => toggleRow('productionCertificate')}>
              <StyledTableData>
                <strong>Certyfikat produkcji:</strong>
              </StyledTableData>
              <StyledTableData>{productData.qualityCertificates}</StyledTableData>
            </StyledTableRow>
            <StyledTableRow onClick={() => toggleRow('productComposition')}>
              <StyledTableData>
                <strong>Skład produktu:</strong>
              </StyledTableData>
              <StyledTableData>{productData.materialComposition}</StyledTableData>
            </StyledTableRow>
            <StyledTableRow onClick={() => toggleRow('materialOrigin')}>
              <StyledTableData>
                <strong>Pochodzenie materiałów:</strong>
              </StyledTableData>
              <StyledTableData>{productData.productLifeCycle.placeOfOrigin}</StyledTableData>
            </StyledTableRow>
            <StyledTableRow onClick={() => toggleRow('longevity')}>
              <StyledTableData>
                <strong>Długowieczność:</strong>
              </StyledTableData>
              <StyledTableData>{productData.productLifeCycle.productDurability}</StyledTableData>
            </StyledTableRow>
            <StyledTableRow onClick={() => toggleRow('usageRecommendations')}>
              <StyledTableData>
                <strong>Zalecenia dotyczące użytkowania:</strong>
              </StyledTableData>
              <StyledTableData>{productData.productLifeCycle.usageRecommendations}</StyledTableData>
            </StyledTableRow>
            <StyledTableRow onClick={() => toggleRow('recyclability')}>
              <StyledTableData>
                <strong>Możliwość recyklingu:</strong>
              </StyledTableData>
              <StyledTableData>{productData.recyclability ? 'Tak' : 'Nie'}</StyledTableData>
            </StyledTableRow>
          </React.Fragment>
        </tbody>
      </StyledTable>
      {/* <StyledButton>Rozwiń szczegóły</StyledButton> */}
    </StyledContainer>
  );
};
